import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "GREAT GYM, I has all the equipment needed for fitness, clean atmosphere and reliable couches",
    name: "MOHAMMED ALI",
    status: "CUSTOMER",
  },
  {
    image: image2,
    review:
      "HERE IN THE GYM, we make sure to make the best fitness plan suitable for you body, achieve your dream with us",
    name: "MOHAMMED AL-DEYHOM",
    status: "COACH",
  },
  {
    image: image3,
    review:
      "GREAT FITNESS CLUB, it has great facilities clean and new machines which help me focus on my training",
    name: "AHMED RAMADAN",
    status: "CUSTOMER",
  },
];
