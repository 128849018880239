import React from "react";
import "./Plan.css";
import whiteTick from "../../assets/whiteTick.png";
import { plansData } from "../../data/plansData";
import { Link } from "react-scroll";
function Plan() {
  return (
    <div className="plans-container">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="programs-header" id="programs-header">
        <span className="stroke-text">READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className="stroke-text">NOW WITH US</span>
      </div>
      {/* Plans card */}
      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span> LD{plan.price}</span>
            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            <div>
              <span>CONTACT US FOR MORE DETAILS...</span>
            </div>
            <button className="btn">
              <Link to="join-us" spy={true} smooth={true}>
                Join Now
              </Link>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Plan;
